import React from 'react'
import styles from '../components/contact.module.css'
var portfolioLogo = require('../images/portfolio-logo.png')

function Contact() {
  return (
    <div id='contact' className={styles["contact-slice"]}>
        <h2 className={styles['contact-title']}>
          Contact Us:
        </h2>
        <p><a href='mailto:jcconnol4@gmail.com'>jcconnol4@gmail.com</a></p>
        <img className={styles['contact-logo']} 
          src={portfolioLogo} 
        />
    </div>
  )
}

export default Contact;