import React from "react";
import styles from '../pages/nopage.module.css'

const NoPage = () => {
  return (
    <div>
      <h1>
        Sorry this page does not exist! 
        <br />
        Please go back to the main page:
        <a href='/'>
          Home
        </a>
      </h1>
    </div>
  );
};
  
export default NoPage;