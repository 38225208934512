import React from 'react'
import styles from './examples.module.css'
import StartButton from './startButton';
var twoMessages = require('../images/two-messages.png');
var pooPersonSkeleton = require('../images/poo-person-skeleton.png');
var pooGrayMessage = require('../images/poo-gray-message.png');
var phoneMessage = require('../images/phone-messages.png');
var grayMessage = require('../images/gray-message.png');
var blueWhiteMessage = require('../images/blue-white-messages.png');

function Examples() {
  return (
    <div id='examples' className={styles["example-slice"]}>
      <div className={styles['inner-container']} >
        <div className={styles['images-container']}>
          <img className={styles['two-message-image']} src={twoMessages} alt=""/>
          <img className={styles['gray-message-box-image']} src={grayMessage} alt="" />
          <img className={styles['poo-person-skeleton-image']} src={pooPersonSkeleton} alt="" />
          <img className={styles['poo-gray-image']} src={pooGrayMessage} alt="" />
          <img className={styles['phone-message-image']} src={phoneMessage} alt="" />
          <img className={styles['blue-white-message-image']} src={blueWhiteMessage} alt="" />
        </div>
        <div className={styles['center-container']}>
          <h2>
            Virtual Turds!
          </h2>
          <p>
            Simple and Funny
          </p>
          <div className={styles["examples-button"]}>
            <StartButton buttonText="Start sending now!"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Examples;