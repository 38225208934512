import React, { useState, useEffect } from 'react';
import styles from '../components/header.module.css';

const Header = (props) =>{
    const [isOpen, setIsOpen] = useState(false);
    const openMenu = () => setIsOpen(!isOpen);

    const [hidden, setHidden] = useState({ isHidden: false, offset: 0 });
    
    const handleScroll = () => {
        if (window.pageYOffset > (300)) {
            setHidden({ isHidden: true, offset: 0 });
        } else {
            setHidden({ isHidden: false, offset: 0 });
        }
    };
    
    useEffect(() => {
        const handleScrollEvent = () => {
            handleScroll()
        }
        
        window.addEventListener('scroll', handleScrollEvent);
    
        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
        
    }, []);

    return(
        <header id="sticky-header" className={`${styles.navbar} ${(hidden.isHidden || props.static) ? '' : styles.hidden}`} >
            <nav className={styles.navbar}>
                <a className={styles.navlogo}>[BrandLogo]</a>
                <ul className={isOpen ? styles.navmenu+" "+styles.active : styles.navmenu}>
                    <a className={styles.navlink} href="/#">
                        <button>
                          <li className={styles.navitem}>
                              Home
                          </li>
                        </button>
                    </a>
                    <a className={styles.navlink} href="/#examples">
                        <button>
                            <li className={styles.navitem}>
                                Examples
                            </li>
                        </button>
                    </a>
                    <a className={styles.navlink} href="/#pricing">
                        <button>
                          <li className={styles.navitem}>
                              Pricing
                          </li>
                        </button>
                    </a>
                    <a className={styles.navlink} href="/#faq">
                        <button>
                        <li className={styles.navitem}>
                            Faq
                        </li>
                        </button>
                    </a>
                    <a className={styles.navlink} href='/#contact'>
                        <button>
                        <li className={styles.navitem}>
                            Contact
                        </li>
                        </button>
                    </a>
                </ul>
                <button className={isOpen ? styles.hamburger+" "+styles.active : styles.hamburger}
                    onClick={openMenu}>
                    <span className={styles.bar}></span>
                    <span className={styles.bar}></span>
                    <span className={styles.bar}></span>
                </button>
            </nav>
        </header>
    )
}

export default Header;