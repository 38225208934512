import React from 'react'
import styles from '../components/pricing.module.css'
import PricingCards from './PricingCards';

function Pricing() {
  return (
    <div id='pricing' className={styles["pricing-slice"]}>
      <h2 className={styles['pricing-header']}>
        Pricing
      </h2>
      <div className={styles['stripe-pricing-table-container']}>
        <stripe-pricing-table 
          pricing-table-id="prctbl_1OJ83cEzWgNNAOCh8ZiIRncn" 
          publishable-key="pk_test_51OIvc1EzWgNNAOChMimsRcaKrTFel5cHM3HoiPuEKjY2G8xjydVM9P6GRDwmlo0wFM5oDcjTehY46LT3gdh7fPbs005mfIfxsE" 
        />
      </div>
    </div>
  )
}

export default Pricing;