import styles from './footer.module.css'
import { FaLinkedinIn } from 'react-icons/fa';
import { BsGithub } from 'react-icons/bs';
import { CgFacebook } from 'react-icons/cg';
import { Link } from "react-router-dom";

//TODO add correct icons and links
//TODO add logo when available

export default function Footer(props) {
  const pageName = props.pageName;

  return (
    <div>
      <footer className={styles.footer}>
        <div className={styles['footer-inner']}>
          <div className={styles["upper-footer"]}>
            <ul className={styles["link-table"]}>
              <li className={styles["list-item"]}>
                <a href="/#" className={styles.link}>Home</a>
              </li>
              <li className={styles["list-item"]}>
                <a href="/#examples" className={styles.link}>Examples</a>
              </li>
              <li className={styles["list-item"]}>
                <Link to="/#pricing" className={styles.link}>Pricing</Link>
              </li>
              <li className={styles["list-item"]}>
                <a href="/#faq" className={styles.link}>FAQ</a>
              </li>
              <li className={styles["list-item"]}>
                <a href="/#contact" className={styles.link}>Contact</a>
              </li>
            </ul>
          </div>
          <hr className={styles['horizontal-line']} />
          <div className={styles["bottom-footer"]}>
            <ul className={styles['legal-list']}>
              <li className={styles['legal-list-item']}>
                <Link className={styles["privacy-policy"]} to="/privacy-policy">
                  Privacy Policy
                </Link>
              </li>
              <li className={styles['legal-list-item']}>
                <Link className={styles["terms"]} to="/terms-of-use">
                  Terms of Use
                </Link>
              </li>
            </ul>
            <p>
            © John Connolly 
            </p>
            
          </div>
        </div>
      </footer>
    </div>
  )
  }