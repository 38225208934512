import React from "react"
import styles from './startbutton.module.css'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Pricing from "./Pricing";

export default function StartButton({ buttonText }) {
  const scrollToPricing = () => {
    document.getElementById("pricing").scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <button className={styles["cta-button"]} onClick={scrollToPricing}>
      Start Sending Now!
    </button>
  )
}