/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Kcxing (https://sketchfab.com/e224984519)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/models/c4f8f93ca6ec4df9994a7d4a7b0a7d74
title: POOP
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes } = useGLTF('/scenes/poop.gltf')

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[0, 0, (-Math.PI / 2)]} position={[0,0,0]}>
        <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
        <mesh geometry={nodes.mesh_1.geometry} material={nodes.mesh_1.material} />
        <mesh geometry={nodes.mesh_2.geometry} material={nodes.mesh_2.material} />
      </group>
    </group>
  )
}

useGLTF.preload('/poop.gltf')
