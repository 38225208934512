import React from 'react';
import ThreeTurd from "../components/3dTurd";
import styles from '../pages/Home.module.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Pricing from "../components/Pricing";
import Features from "../components/Features";
import Examples from "../components/Examples";
import Contact from "../components/Contact";
import Faq from "../components/Faq"
import StartButton from "../components/startButton";

const Home = () => {
  var width = window.innerWidth;

  return (
    <div>
      <Header />
      <div className={styles.firstSlice}>
        <div className={styles['left-side']}>
          <h1>
            Virtual Turd
          </h1>
          <h2>
            Schedule to send turd facts anonymously to your friends! 
          </h2>
          <StartButton buttonText="Start Sending Now!" />
          <div>
            <a href='/unsubscribe'>Click here to unsubscribe</a>
          </div>
        </div>
        {
          width > 500 ? 
            <div className={styles['right-side']}>
              <ThreeTurd />
            </div>
          : null
        }
        
      </div>
      <Features />
      <Examples />
      <Pricing />
      <Faq />
      <Contact />
      <Footer />
    </div>
  );
};
  
export default Home;

