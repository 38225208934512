import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import styles from '../components/faq.module.css'
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

const Faq = () => {
  return (
    <div id='faq' className={styles.faq}>
      <h1 className='text-center'>Frequently Asked Questions</h1>
      <div className={`row d-flex justify-content-center ${styles['faq-accordians']}`}>
        <Accordion className='p-3 '>
          <Card>
            <Card.Header className='p-3 bg-gradient text-white link-success'>
              <CustomToggle eventKey="0" className={`${styles['custom-accordion-button']} p-3`}>
                Why turds?
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                Everyone poops!
                <br />
                Also, most everyone thinks a little toilet humor is funny every once in a while.
                The facts sent to people are real and true facts but signing a friend up for poop facts is an almost inherently funny act.
                If anything it will make a funny story for your friends.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className='p-3 bg-gradient text-white link-success'>
              <CustomToggle eventKey="1" className={`${styles['custom-accordion-button']} link-success p-3`}>
                I didn't sign up for this, why am I receiving turd facts?
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                This is intended to be used as a joke to friends. More than likely someone you know has signed you up for this. 
                Since this is likely a joke, have fun with it and try to find out who signed you up and get some good laughs out of it.
                In the future, feel free to sign up another one of your friends and let the fun begin again!
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className='p-3 bg-gradient text-white link-success'>
              <CustomToggle eventKey="2" className={`${styles['custom-accordion-button']} link-success p-3`}>
                Why aren't there funnier messages?
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                The messages sent from this are true poop facts. Some are kind of funny and others not as much. 
                If you find a fact that you want to be added to the list then please send it to us with a link to the article that has the fact in it.
                There are no guarantees that it will be included when you sign someone up but there is a possibility.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className='p-3 bg-gradient text-white link-success'>
              <CustomToggle eventKey="3" className={`${styles['custom-accordion-button']} link-success p-3`}>
               Why isn't there an unlimited Virtual Turd plan?
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                Text messages cost money per text sent and to account for this there is no unlimited plan.
                Also, sending too many facts to a single person will eventually make them unsubscribe and it will be illegal to resubscribe them.
                There not being an unlimited plan prevents people from wasting money.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header className='p-3 bg-gradient text-white link-success'>
              <CustomToggle eventKey="4" className={`${styles['custom-accordion-button']} link-success p-3`}>
                Why does the first message provide the user a way to unsubscribe/direct to your website?
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                By law, when someone gets sent a text message from a business they must be given the option to opt out.
                To abide by this law and not be fined, the first text gives people information about how to opt out.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
};

function CustomToggle({ children, eventKey }) {
  const [collapse, setCollapse] = useState('false');

  const decoratedOnClick = useAccordionButton(eventKey, () =>
    setCollapse(!collapse)
  );

  return (
    <button
      type="button"
      className={`${styles['custom-accordion-button']} ${collapse ? styles.collapsed : ''}`}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}
  
export default Faq;