import React from 'react';
import styles from '../pages/unsubscribe.module.css'
import Header from '../components/Header'
  
const Unsubscribe = () => {
  return (
    <div>
      <Header static={true} />
      <div className={styles['first-slice']}>
        <h1>Unsubscribe</h1>
        <p>
          Did you get texts from Virtual Turd that you no longer want to receive? 
        </p>
        <p>
          You can input your number and unsubscribe.
        </p>
        <div className={styles['unsub-form']}>
          <div>
            <input className={styles['unsub-input']} />
          </div>
          <div className={styles['unsub-button-container']}>
            <button className={styles['unsub-button']}>
              Unsubscribe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
  
export default Unsubscribe;