import React, { Suspense } from 'react';
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from '@react-three/drei';
import Poop from '../components/Poop'
import styles from '../components/3dTurd.module.css'

const ThreeTurd = () => {
    return (
        <div className={styles['three-d-turd']}>
            <Canvas className='canvas'>
                <OrbitControls 
                    enableZoom={false} 
                    autoRotate={true}
                    autoRotateSpeed={4}
                    near={-100}
                    />
                <ambientLight intensity={0.7} />
                <directionalLight position={[-2, 5, 2]} intensity={1} />
                <Suspense fallback={null}>
                    <Poop />
                </Suspense>
            </Canvas>
        </div>
    )
};
  
export default ThreeTurd;