import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import Unsubscribe from './pages/Unsubscribe'
import NoPage from './pages/NoPage'
import Pricing from './components/Pricing';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route index element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="*" element={<NoPage />} />
          <Route component={Pricing} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
