import React from 'react'
import styles from '../components/features.module.css'
import {TiMessages} from 'react-icons/ti'
import { MdSchedule } from 'react-icons/md';
import { AiOutlineShoppingCart } from 'react-icons/ai'
import PhoneTexts from '../images/PhoneTexts.gif'

function Features() {
  return (
    <div id='features' className={styles["features-slice"]}>
      <div className={styles.header}>
        Some Amazing Features 
      </div>
      <div className={styles.row}>
        <div className={styles.column} >
          <div className={styles['facts-container']}>
            <div className={styles.fact}>
              <div className={styles['fact-icon-container']}>
                <TiMessages className={styles['fact-icon']}/>
              </div>
              <h3 className={styles['fact-text']}>
                Send funny texts to your friends.
              </h3>  
            </div>
            <div className={styles.fact}>
              <div className={styles['fact-icon-container']}>
                <MdSchedule className={styles['fact-icon']}/>
              </div>
              <h3 className={styles['fact-text']}>
                Control the interval of the Turd Facts.
              </h3>
            </div>
            <div className={styles.fact}>
              <div className={styles['fact-icon-container']}>
                <AiOutlineShoppingCart className={styles['fact-icon']}/>
              </div>
              <h3 className={styles['fact-text']}>
                Control the amount of Turd facts that are sent
              </h3>
            </div>
          </div>
        </div>
        <div className={`${styles.column} ${styles['phone-container']}`}>
          <img 
            src={PhoneTexts} 
            className={styles['phone-gif']} />
        </div>
      </div>
    </div>
  )
}

export default Features;